import styled from "styled-components";

const MessageInput = styled.input`
  padding: 10px 18px 10px 18px;
  width: 60%;
  font-size: 1.3em;
  border: 1px solid #cdcdcd;
  border-radius: 10px;
`;

export default MessageInput;
