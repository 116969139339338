import axios from 'axios';

//const API_URL = 'http://localhost:3000';
const API_URL = 'https://catchupapi-dot-catchup-243913.appspot.com';


const handleAuthenticationResult = result => {
  if (result.status === 200) {
    let isSuccess = result.data.token !== "";
    return {
      "isSuccess": isSuccess,
      "token": result.data.token,
      "userID": result.data.userID,
      "firstName": result.data.firstName,
      "isParent": result.data.isParent,
      "colour": result.data.colour,
      "photo": result.data.photo
    };
  }
  else {
    return {
      "isSuccess": false,
      "token": null
    };
  }
};

const data = {
  account: async (token) => {
    try {
      const account = await axios.post(`${API_URL}/account/`, {
        "token": token
      });
      return account.data;
    } catch (e) {
      console.log("Error retrieving account information");
      return null;
    }
  },
  authenticate: async (username, password) => {
    try {
      const loginResult = await axios.post(`${API_URL}/authenticate/`, {
        "username": username,
        "password": password
      });
      return handleAuthenticationResult(loginResult);
    } catch (e) {
      console.log("Error when logging in", e);
      return {
        isSuccess: false
      };
    }
  },
  authenticateToken: async (token) => {
    try {
      const authenticateTokenResult = await axios.post(`${API_URL}/authenticateToken/`, {
        "token": token
      });
      return handleAuthenticationResult(authenticateTokenResult);
    } catch (e) {
      console.log("Your login token has expired - please log in again");
      return {
        isSuccess: false
      };
    }
  },
  chats: async (token) => {
    const chatResult = await axios.post(`${API_URL}/chats/`, {
      "token": token
    });
    if (chatResult.data === "")
      return [];
    return chatResult.data;
  },
  chatMessages: async (token, chatID) => {
    const chatMessages = await axios.post(`${API_URL}/chatMessages/`, {
      "token": token,
      "chatID": chatID
    });
    return chatMessages.data;
  },
  children: async (token) => {
    const children = await axios.post(`${API_URL}/children/`, {
      "token": token
    });
    return children.data;
  },
  isUsernameExists: async (token, username) => {
    const isUsernameExists = await axios.post(`${API_URL}/isUsernameExists/`, {
      "token": token,
      "username": username
    });
    return isUsernameExists.data;
  },
  message: async (token, chatID, messageText) => {
    const message = await axios.post(`${API_URL}/message/`, {
      "token": token,
      "chatID": chatID,
      "message": messageText
    });
    return message.data;
  },
  rateMessage: async (token, messageID, messageRating) => {
    const message = await axios.post(`${API_URL}/message/rate/`, {
      "token": token,
      "messageID": messageID,
      "messageRating": messageRating
    });
    return message.data;
  },
  sendFeelingsDiaryRecord: async (token, feelings, freeText) => {
    const message = await axios.post(`${API_URL}/sendFeelingsDiaryRecord/`, {
      "token": token,
      "feelings": feelings,
      "freeText": freeText
    });
    return message.data;
  },
  updateColour: async (token, colour) => {
    const message = await axios.post(`${API_URL}/updateColour/`, {
      "token": token,
      "colour": colour
    });
    return message.data;
  },
  updatePassword: async (token, newPassword) => {
    const updatePasswordResult = await axios.post(`${API_URL}/updatePassword/`,{
      "token": token,
      "newPassword": newPassword
    });
    return updatePasswordResult.data;
  },
  addChild: async (token, childData) => {
    const addChildResult = await axios.post(`${API_URL}/child/add/`,{
      "token": token,
      "firstName": childData.firstName,
      "lastName": childData.lastName,
      "username": childData.username,
      "password": childData.password
    });
    return addChildResult.data;
  }
};

export default data;
