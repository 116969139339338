import React from "react";
import config from "../../config";
import {UserCircle} from "styled-icons/fa-regular";

const UserIconContainer = props => {
  return (
    <UserCircle
      size={38}
      style={{width: "8%", maxWidth:"32px", marginRight: "10px", color:`${config.colours.text}`}}
    />
  )
}

export default UserIconContainer;
