import React from "react";

import FeelingSelectorContainer from "./FeelingSelectorContainer";
import getFeelingEmoji from "../../utilities/getFeelingEmoji";

const FeelingSelector = props => {
  return (
    <FeelingSelectorContainer isSelected={props.isSelected} onClick={() => props.toggleSelectFeeling(props.id)}>
      {getFeelingEmoji(props.emoji, 76)}
      <div style={{marginTop:"5px"}}>{props.name}</div>
    </FeelingSelectorContainer>
  )
};

export default FeelingSelector;
