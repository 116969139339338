import React from 'react';
import { withRouter } from 'react-router-dom';
import { withCookies } from "react-cookie";
import { EllipsisH } from "styled-icons/fa-solid";
import moment from 'moment';

import {processChatData, processChats} from './utilities/processMessages';
import logo from './logo.svg';
import './App.css';
import smiley from './static/images/smiley.png';
import title from './static/images/title.png';

import AppContainer from './components/shared/AppContainer';
import AppHeader from './components/header/AppHeader';
import ChatContainer from './components/chats/ChatContainer';
import ChatMessageContainer from './components/chat/ChatMessageContainer';
import FeelingsDiary from './components/feelingsDiary/FeelingsDiary';
import getChatMembershipsFromID from "./utilities/getChatMembershipsFromID";
import getChatTitleFromID from "./utilities/getChatTitleFromID";
import LoginPage from './components/frontpage/LoginPage';
import Flex from './components/shared/Flex';
import Menu from './components/header/Menu';
import MenuItem from './components/header/MenuItem';
import MyAccount from "./components/account/MyAccount";
import ParentInfoContainer from "./components/parent/ParentInfoContainer";

import config from './config';
import data from './utilities/data';
import EmojiList from "./components/emojis/EmojiList";
import addDisplayTimeToChats from "./utilities/addDisplayTimeToChats";

let refreshChats;

class App extends React.Component {
  state = {
    isCheckingToken: true,
    isLoggedIn: false,
    isLoggingIn: false,
    isLoginError: false,
    userID: undefined,
    firstName: "",
    isLoadingChats: true,
    chats: [],
    token: "",
    colour: "",
    photo: "",
    chatId: null,
    chatTitle: "",
    chatMemberships: [],
    chatMessages: [],
    isShowMenu: false,
    isParent: false,
    isShowMyAccountScreen: false,
    isShowParentScreen: false,
    isShowEmotionLog: false,
    isShowEmojiList: false
  };
  componentDidMount = async () => {
    await this.checkToken();
  }
  authenticate = async (username, password) => {
    await this.setState({isLoggingIn: true});
    const loginResult = await data.authenticate(username, password);
    await this.handleAuthenticationResult(loginResult);
    await this.setState({isLoggingIn: false});
  };
  checkToken = async () => {
    if (this.props.cookies.get("catchupSession") !== undefined) {
      const authenticateTokenResult = await data.authenticateToken(this.props.cookies.get("catchupSession"));
      this.handleAuthenticationResult(authenticateTokenResult);
    }
    else {
      await this.setState({ isCheckingToken: false});
    }
  };
  handleAuthenticationResult = async (result) => {
    if (result.isSuccess === true) {
      await this.setState({
        isLoggedIn: true,
        isLoggingIn: false,
        isCheckingToken: false,
        isLoginError: false,
        token: result.token,
        firstName: result.firstName,
        userID: result.userID,
        isParent: result.isParent,
        colour: result.colour,
        photo: result.photo
      });
      this.saveAuthenticationCookie(result.token);
      this.loadChats();
      refreshChats = setInterval(this.loadChats, 4000);
    }
    else
      await this.setState({
        isLoggingIn: false,
        isCheckingToken: false,
        isLoginError: true
      });
  };
  loadChats = async () => {
    let chats = await data.chats(this.state.token);
    addDisplayTimeToChats(chats);
    processChats(chats);
    await this.setState({
      isLoadingChats: false,
      chats: chats
    });
  };
  loadChat = async (id) => {
    const chatData = await data.chatMessages(this.state.token, id);
    const chatMemberships = await getChatMembershipsFromID(id, this.state.chats);
    for (let i = 0; i < chatData.length; i++) {
      chatData[i].displayTime = moment(chatData[i].modifiedAt).format('Do MMM YYYY - h:mma');
    }
    let processedChatData = processChatData(chatData);
    await this.setState({
      chatMessages: processedChatData,
      chatMemberships: chatMemberships
    });
  };
  logout = async () => {
    this.removeAuthenticationCookie();
    await this.setState({
      isLoggedIn: false,
      isParent: false,
      token: "",
      chats: [],
      chatId: null
    });
  };
  message = async message => {
    await data.message(
      this.state.token,
      this.state.chatId,
      message
    );
    this.loadChat(this.state.chatId);
  };
  removeAuthenticationCookie = () => { this.props.cookies.remove("catchupSession"); };
  saveAuthenticationCookie = token => { this.props.cookies.set("catchupSession", token, {path: "/", maxAge: 86400}) };
  setChatId = async (id) => {
    await this.setState({
      chatId: id,
      isShowMenu: false,
      isShowMyAccountScreen: false,
      isShowParentScreen: false,
      isShowEmotionLog: false,
      isShowEmojiList: false
    });
    if (id !== null) {
      await this.setState({
        chatTitle: getChatTitleFromID(id, this.state.chats),
        chatMessages: [],
        chatMemberships: []
      });
      this.loadChat(id);
      this.startCheckForNewChatMessages(id);
    }
    else
      this.stopCheckForNewChatMessages();
  };
  setColour = async colour => { await this.setState({colour: colour}); }
  showEmotionLog = async () => { await this.setState({isShowEmotionLog: true})};
  startCheckForNewChatMessages = id => {
    refreshChats = setInterval(() => {
      this.loadChat(id);
    }, config.checkForNewMessageIntervalMilliseconds);
  };
  stopCheckForNewChatMessages = () => { clearInterval(refreshChats); };
  toggleMenu = async () => { await this.setState({isShowMenu: !this.state.isShowMenu}); };
  toggleMyAccountView = async () => { await this.setState({isShowMyAccountScreen: !this.state.isShowMyAccountScreen}); };
  toggleParentView = async () => { await this.setState({isShowParentScreen: !this.state.isShowParentScreen}); };
  toggleEmojiList = async () => { await this.setState({isShowEmojiList: !this.state.isShowEmojiList}); };
  render()
  {
    let display = 'login';
    if (this.state.isLoggedIn && !this.state.isLoggingIn && this.state.chatId === null)
      display = 'chats';
    if (this.state.chatId !== null)
      display = 'chat';
    if (this.state.isShowMyAccountScreen)
      display = 'myaccount';
    if (this.state.isShowParentScreen)
      display = 'parent';
    if (this.state.isCheckingToken)
      display = 'checkingToken';
    if (this.state.isShowEmotionLog)
      display = 'emotionlog';
    if (this.state.isShowEmojiList)
      display = 'emojilist';

    return (
      <AppContainer>
        <AppHeader>
          <Flex onClick={() => this.setChatId(null)} style={{cursor: "pointer"}}>
            <img src={smiley} style={{height: "38px"}} alt={logo} />
            <img src={title} style={{height: "42px"}} alt={title} />
            {/*{this.state.isParent && <ParentBadge />}*/}
          </Flex>
          {
            (display === 'chats' || display === 'chat')
            &&
            <div>
              <EllipsisH size={24} style={{marginRight:"25px", color: "#ffff00", cursor: "pointer"}} onClick={this.toggleMenu} />
            </div>
          }
        </AppHeader>
        {
          display === 'login'
          &&
          <LoginPage authenticate={this.authenticate} isLoggingIn={this.state.isLoggingIn} isLoginError={this.state.isLoginError} />
        }
        {
          this.state.isShowMenu
          &&
          <Menu onClick={this.toggleMenu}>
            {this.state.isLoggedIn && <p>Welcome, {this.state.firstName}</p>}
            <hr />
            <MenuItem onClick={this.toggleMyAccountView}>My Account</MenuItem>
            {this.state.isParent && <MenuItem onClick={this.toggleParentView}>My Children</MenuItem>}
            <MenuItem onClick={this.toggleEmojiList}>Emoji List</MenuItem>
            <MenuItem onClick={this.showEmotionLog}>My Feelings Diary</MenuItem>
            {this.state.isParent && <hr />}
            <MenuItem onClick={this.logout}>Log out</MenuItem>
          </Menu>
        }
        {
          display === 'chats'
          &&
          <ChatContainer
            chats={this.state.chats}
            isLoadingChats={this.state.isLoadingChats}
            setChatId={this.setChatId}
          />
        }
        {
          display === 'chat'
          &&
          <ChatMessageContainer
            chatId={this.state.chatId}
            chatTitle={this.state.chatTitle}
            chatMessages={this.state.chatMessages}
            chatMemberships={this.state.chatMemberships}
            message={this.message}
            userID={this.state.userID}
            setChatId={this.setChatId}
            isLoadingChats={this.state.isLoadingChats}
            token={this.state.token}
          />
        }
        {
          display === 'myaccount'
          &&
          <MyAccount
            firstName={this.state.firstName}
            setChatId={this.setChatId}
            colour={this.state.colour}
            setColour={this.setColour}
            token={this.state.token}
            photo={this.state.photo}
          />
        }
        {
          display === 'parent'
          &&
          <ParentInfoContainer
            setChatId={this.setChatId}
            token={this.state.token}
          />
        }
        {
          display === 'emotionlog'
          &&
          <FeelingsDiary
            setChatId={this.setChatId}
            isParent={this.state.isParent}
            token={this.state.token}
          />
        }
        {
          display === 'emojilist'
          &&
          <EmojiList toggleEmojiList={this.toggleEmojiList} />
        }
      </AppContainer>
    );
  }
}

export default withRouter(withCookies(App));
