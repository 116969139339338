import React from "react";

import Button from "../shared/Button";
import Flex from "../shared/Flex";
import getFeelingEmoji from "../../utilities/getFeelingEmoji";
import HorizontalRule from "../shared/HorizontalRule";

import FeelingsDetailTextAreInput from "./FeelingsDetailTextAreInput";
import PositiveStatement from "./PositiveStatement";

class FeelingsDetail extends React.Component {
  state = {
    currentFeelingIndex: 0,
    selectedFeelings: []
  };
  componentDidMount = async () => {
    await this.getSelectedFeelings();

    console.log("selected feelings", this.state.selectedFeelings);

    if (this.state.selectedFeelings.length < 1)
      await this.props.showPage('finishedPage');
  };
  getSelectedFeelings = async () => {
    let selectedFeelings = [];
    for (let i = 0; i < this.props.feelings.length; i++) {
      if (this.props.feelings[i].isSelected === true) {
        let selectedFeeling = this.props.feelings[i];
        selectedFeelings.push(selectedFeeling);
      }
    }
    await this.setState({selectedFeelings: selectedFeelings});
  };
  goToNextFeeling = async () => {
    if (this.state.currentFeelingIndex === (this.state.selectedFeelings.length - 1))
      this.props.showPage('freeText');
    this.setState({currentFeelingIndex: this.state.currentFeelingIndex + 1});
  };
  updateDetailedResponse = async e => {
    const feelingID = Number(e.currentTarget.dataset.currentfeelingid);
    const questionIndex = Number(e.currentTarget.dataset.questionindex);
    const newValue = e.currentTarget.value;
    let updatedFeelings = this.state.selectedFeelings;
    for (let i = 0; i < updatedFeelings.length; i++) {
      if (updatedFeelings[i].id === feelingID) {
        updatedFeelings[i].answers[questionIndex] = newValue;
      }
    }
    await this.setState({ selectedFeelings: updatedFeelings});
  };
  render() {
    if (this.state.selectedFeelings.length < 1)
      return "";
    return (
      <div>
        <Flex style={{justifyContent:"flex-start"}}>
          {getFeelingEmoji(this.state.selectedFeelings[this.state.currentFeelingIndex].emoji, 48)}
          <h3 style={{marginLeft:"10px"}}>{this.state.selectedFeelings[this.state.currentFeelingIndex].name}</h3>
        </Flex>

        <HorizontalRule />
        {
          this.state.selectedFeelings[this.state.currentFeelingIndex].questions.map(
            (question, index) => {
              return (
                <div key={question}>
                  <p>{question}</p>
                  <FeelingsDetailTextAreInput
                    data-currentfeelingid={this.state.selectedFeelings[this.state.currentFeelingIndex].id}
                    data-questionindex={index}
                    onChange={this.updateDetailedResponse}
                    value={this.state.selectedFeelings[this.state.currentFeelingIndex].answers[index]}
                  />
                </div>
              );
            }
          )
        }
        <HorizontalRule />
        <PositiveStatement>
          <h4 style={{marginTop:"0px"}}>Your Positive Statement:</h4>
          <p>{this.state.selectedFeelings[this.state.currentFeelingIndex].positiveStatement}</p>
        </PositiveStatement>
        <HorizontalRule />
        <Flex>
          <Button
            onClick={() => this.props.showPage('feelingSelector')}
            style={{minWidth:"100px", textAlign: "center"}}
          >
            Start again
          </Button>
          <Button
            onClick={this.goToNextFeeling}
            style={{minWidth:"100px", textAlign: "center"}}
          >
            Next
            {/*{this.state.currentFeelingIndex === (this.state.selectedFeelings.length - 1) ? `Finish` : `Next`}*/}
          </Button>
        </Flex>
      </div>
    );
  }
};

export default FeelingsDetail;
