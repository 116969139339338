import styled from 'styled-components';
import config from '../../config';

const Input = styled.input`
  padding: 7px;
  font-family: ${config.fonts.display}, ${config.fonts.display}, sans-serif;
  font-size: 1.7em;
  border: 1px solid #232323;
  border-radius: 5px;
  margin-top: 5px;
  margin-bottom: 10px;
  width: 100%;
`;

export default Input;
