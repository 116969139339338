import styled from "styled-components";

const ListItem = styled.div`
  padding: 5px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  
  &:hover {
    background-color: #ababab;
  }
`;

export default ListItem;
