import styled from 'styled-components';

const MenuItem = styled.div`
  cursor: pointer;
  margin-top: 10px;
  
  &:hover {
   color: #ff0000;
  }
`;

export default MenuItem;
