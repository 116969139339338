import config from "../config";

const getTextColourFromBackgroundColour = colourHexString => {
  let lightColours = config.lightColours;
  for (let i = 0; i < lightColours.length; i++) {
    if (lightColours[i] === colourHexString)
      return '#000000';
  }
  return '#ffffff';
};

export default getTextColourFromBackgroundColour;
