const feelings = [
  {
    id: 1,
    name: "Worried",
    emoji: "worried",
    questions: [
      "What are you worried about?",
      "What facts do you have to back up these worries?",
      "What have you done before to get over your worries?"
    ],
    positiveStatement: "I am calm. I have the answers."
  },
  {
    id: 2,
    name: "Sad",
    emoji: "sad",
    questions: [
      "What happened to make you feel sad?",
      "How can you leave the sadness behind?"
    ],
    positiveStatement: "It's normal to feel sad from time to time. This will pass, just like other feelings."
  },
  {
    id: 3,
    name: "Lonely",
    emoji: "lonely",
    questions: [
      "How did you become lonely?",
      "What could you do to share your feelings and help yourself?"
    ],
    positiveStatement: "I can't wait to mix with other children again."
  },
  {
    id: 4,
    name: "Angry",
    emoji: "angry",
    questions: [
      "What has caused you to feel angry?",
      "What is this anger doing to you, your friends and your family?",
      "What part are you responsible for and what misunderstandings could there be?"
    ],
    positiveStatement: "I recognise my anger. I pause to think it through. I rise above it."
  },
  {
    id: 5,
    name: "Guilty",
    emoji: "guilty",
    questions: [
      "What are you feeling guilty about and why?",
      "How will you act differently next time?"
    ],
    positiveStatement: "I am always learning how to be an even better person."
  },
  {
    id: 6,
    name: "Irritated",
    emoji: "irritated",
    questions: [
      "What is irritating you and why?",
      "How can you let this go?"
    ],
    positiveStatement: "I choose to be flexible and find another way."
  },
  {
    id: 7,
    name: "Bored",
    emoji: "bored",
    questions: [
      "How would you like to feel instead?",
      "What do you need to do to make this happen?"
    ],
    positiveStatement: "It's up to me to make life interesting."
  },
  {
    id: 8,
    name: "Proud",
    emoji: "proud",
    questions: [
      "What are you feeling proud of at the moment?",
      "What does this tell you about yourself?"
    ],
    positiveStatement: "I am proud of everything I achieve."
  },
  {
    id: 9,
    name: "Love",
    emoji: "love",
    questions: [
      "What and who do you love?",
      "How does that make you feel inside?",
      "How has giving and receiving love helped you in your life?"
    ],
    positiveStatement: "I accept who I am and I love my life."
  },
  {
    id: 10,
    name: "Grateful",
    emoji: "grateful",
    questions: [
      "What are you grateful for and why?",
      "How do you show your gratitude?"
    ],
    positiveStatement: "I am thankful for so much in my life."
  },
  {
    id: 11,
    name: "Happy",
    emoji: "happy",
    questions: [
      "What are you feeling happy about?",
      "How can you create even more happiness at home and at school?"
    ],
    positiveStatement: "Happiness is a choice."
  },
  {
    id: 12,
    name: "Jealous",
    emoji: "jealous",
    questions: [
      "What are you feeling jealous about?",
      "How is this jealousy affecting you?",
      "What makes you and your life special too?"
    ],
    positiveStatement: "We are all unique. I choose to make comparisons with my previous self."
  }
];

export default feelings;
