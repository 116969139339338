import React from 'react';
import ContentContainer from '../shared/ContentContainer';
import DisplayLabel from "../shared/DisplayLabel";
import {ArrowBack} from "styled-icons/boxicons-regular";

import AddChild from "./AddChild";
import data from '../../utilities/data';
import ChildInfoContainer from "./ChildInfoContainer";
import { ArrowDown, ArrowUp } from "styled-icons/fa-solid";
import HorizontalRule from "../shared/HorizontalRule";
import config from "../../config";
import ListItem from "../shared/ListItem";
import ListItemInfo from "../shared/ListItemInfo";
import UserIconContainer from "../shared/UserIconContainer";
import Button from "../shared/Button";

class ParentInfoContainer extends React.Component {
  state = {
    isLoading: true,
    children: [],
    averageHappy: 0,
    averageSad: 0,
    childID: null,
    childData: {},
    isAddingChild: false
  };
  componentDidMount = async () => {
    await this.loadChildren();
    // this.openChildView(this.state.children[0])
  };
  closeChildView = async () => {
    await this.setState({childID: null, childData: {}});
  };
  closeParentView = async () => {
    this.props.setChatId(null);
  };
  loadChildren = async () => {
    const children = await data.children(this.props.token);
    await this.setState({
      isLoading: false,
      children: children
    });
  }
  openChildView = async childData => {
    await this.setState({
      childID: childData.childID,
      childData: childData
    });
  };
  toggleAddChildView = async () => {
    await this.setState({ isAddingChild: !this.state.isAddingChild});
    if (this.state.isAddingChild === false)
      this.loadChildren();
  };
  render() {
    if (this.state.isLoading === true) {
      return (
        <ContentContainer>
          <p>Loading</p>
        </ContentContainer>
      );
    }
    if (this.state.isAddingChild === true) {
      return (
        <ContentContainer>
          <AddChild
            token={this.props.token}
            toggleAddChildView={this.toggleAddChildView}
          />
        </ContentContainer>
      )
    }
    if (this.state.children.length > 0 && this.state.childID === null) {
      return (
        <ContentContainer>
          <DisplayLabel>
            <ArrowBack
              size={32}
              onClick={this.closeParentView}
              style={{marginRight:"10px", cursor: "pointer"}}
            />
            My children
          </DisplayLabel>
          <HorizontalRule />
          {this.state.isLoading === true && <p>Loading</p>}
          {
            this.state.children.length > 0 && this.state.childID === null
            &&
            this.state.children.map(child => {
              return (
                <ListItem key={child.childID} onClick={() => this.openChildView(child)} >
                  <UserIconContainer />
                  <ListItemInfo>
                    {child.firstName} {child.lastName}
                  </ListItemInfo>
                </ListItem>
              )
            })
          }
          <Button onClick={this.toggleAddChildView} style={{marginTop:"30px"}}>
            Add child
          </Button>
          {/*{*/}
          {/*  this.state.childID !== null*/}
          {/*  &&*/}
          {/*  <ChildInfoContainer childID={this.state.childID} />*/}
          {/*}*/}
        </ContentContainer>
      )
    }
    if (this.state.childID !== null) {
      return (
        <ContentContainer>
          <DisplayLabel>
            <ArrowBack
              size={32}
              onClick={this.closeChildView}
              style={{marginRight:"10px", cursor: "pointer"}}
            />
            {this.state.childData.firstName} {this.state.childData.lastName}
          </DisplayLabel>
          <HorizontalRule />
          <ChildInfoContainer childData={this.state.childData} />
        </ContentContainer>
      )
    }
  }
}

export default ParentInfoContainer;
