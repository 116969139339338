import styled from 'styled-components';
import config from '../../config';

const Button = styled.button`
  padding: 7px;
  font-family: ${config.fonts.secondaryDisplay}, "Arial", sans-serif;
  font-size: 1.5em;
  border-radius: 5px;
  background-color: ${config.colours.highlight};
  margin-top: 5px;
  width: 150px;
  cursor: pointer;
  border: 1px solid ${config.colours.highlight};
  
  &:hover {
    color: white;
  }
`;

export default Button;
