import styled from "styled-components";

const Button = styled.div`
  background-color: white;
  padding: 6px 15px 6px 15px;
  cursor: pointer;
  margin-left: 10px;
  border: 1px solid #cdcdcd;
  border-radius: 7px;
  
  &:hover {
    background-color: #ababab;
  }
`;

export default Button;
