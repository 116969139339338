import styled from "styled-components";

const HorizontalRule = styled.div`
  margin-top: 0px;
  margin-bottom: 10px;
  padding-bottom: 10px;
  border-bottom: 1px solid #676767;
`;

export default HorizontalRule;
