import styled from 'styled-components';

const ContentContainer = styled.div`
  position: absolute;
  top: 48px;
  left: 0px;
  right: 0px;
  padding: 10px;
  max-height: calc(100%-100px);
  overflow: hidden;
`;

export default ContentContainer;
