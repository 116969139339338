import styled from "styled-components";

const FeelingsContainer = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-wrap: wrap;
`;

export default FeelingsContainer;
