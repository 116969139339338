import moment from "moment";

const addDisplayTimeToChats = chats => {
  for (let i = 0; i < chats.length; i++) {
    chats[i].displayTime = moment.utc(chats[i].modifiedAt).from(moment.utc());
  }
  return chats;
}

export default addDisplayTimeToChats;
