import styled from "styled-components";

const FeelingsDetailTextAreInput = styled.textarea`
  width: 90%;
  font-size: 1.2em;
  padding: 7px;
  border-radius: 7px;
`;

export default FeelingsDetailTextAreInput;
