import styled from "styled-components";

import config from "../../config";

const ParentNote = styled.div`
  padding: 15px;
  border-radius: 7px;
  background-color: ${config.colours.parentNote};  
`;

export default ParentNote;
