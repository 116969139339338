import styled from 'styled-components';
import config from '../../config';

const AppContainer = styled.div`
  top: 0px;
  bottom: 0px;
  width: 100%;
  padding: 0px;
  margin: 0px;
  background-color: ${config.colours.background};
  color: ${config.colours.text};
`;

export default AppContainer;
