import styled from 'styled-components';

const SendMessageContainer = styled.div`
  position: fixed;
  margin: 0px;
  height: 65px;
  padding-left: 35px;
  margin: 0px;
  width: 100%;
  bottom: 0px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
`;

export default SendMessageContainer;
