import styled from "styled-components";

import config from "../../config";

const PositiveStatement = styled.div`
  width: 90%;
  font-size: 1.8em;
  background-color: #9bc3e1;
  border-radius: 14px;
  color: #ffffff;
  font-weight: bold;
  padding: 20px;
`;

export default PositiveStatement;
