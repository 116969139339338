import React from 'react';
import ContentContainer from "../shared/ContentContainer";
import DisplayLabel from "../shared/DisplayLabel";
import {ArrowBack} from "styled-icons/boxicons-regular";

import { emojis, isSecretEmoji} from "../../utilities/processMessages";

import HorizontalRule from "../shared/HorizontalRule";
import Flex from "../shared/Flex";

class EmojiList extends React.Component {
  state = {};
  componentDidMount = async () => {
    console.log ("Emoji List loaded");
  };
  render() {
    return (
      <ContentContainer>
        <DisplayLabel>
          <ArrowBack
            size={32}
            onClick={() => this.props.toggleEmojiList()}
            style={{marginRight:"10px", cursor: "pointer"}}
          />
          Emoji List
        </DisplayLabel>
        <HorizontalRule />
        <p>Remember to include the (brackets) and make sure there are no capital letters.</p>
        {
          emojis.map(
            emoji => {
              return (
                <Flex key={emoji.text}>
                  <div>
                    {!isSecretEmoji(emoji.text) && emoji.text}
                  </div>
                </Flex>

              )
            }
          )
        }
      </ContentContainer>
    );
  }
}

export default EmojiList;
