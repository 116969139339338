import React from 'react';

import ContentContainer from '../shared/ContentContainer';
import { ArrowBack, CaretDown, CaretUp } from "styled-icons/boxicons-regular";
import { Circle, Smile } from "styled-icons/boxicons-solid";

import ChatTitle from "./ChatTitle";
import DisplayLabel from '../shared/DisplayLabel';
import EmojiSelector from "../emojis/EmojiSelector";
import Message from './Message';
import MessageInput from "./MessageInput";
import SendMessage from './SendMessage';
import Button from "../shared/Button";
import Flex from "../shared/Flex";
import config from "../../config";

const scrollToMostRecentMessage = () => {
  const messageContainerEnd = document.getElementById('messageContainerEnd');
  messageContainerEnd.scrollIntoView();
};

const ChatParticipant = props => {
  return (
    <Flex style={{justifyContent:"flex-start"}}>
      <div style={{paddingLeft:"30px"}}>
        <Circle style={{color: props.colour}} size={64} />
      </div>
      <div style={{color:"#ffff00", fontSize:"1.3em", marginLeft:"10px"}}>
        {props.name}
      </div>
    </Flex>
  )
};

class ChatMessageContainer extends React.Component {
  state = {
    message: "",
    isSendingMessage: false,
    isShowChatDetails: false,
    isShowEmojiSelector: false
  };
  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.chatMessages.length !== this.props.chatMessages.length) {
      scrollToMostRecentMessage();
    }
    this.nameInput.focus();
  };
  addText = async text => {
    await this.setState({message: `${this.state.message}${text}`});
  };
  getColourFromMessageUserID = userID => {
    for (let i = 0; i < this.props.chatMemberships.length; i++) {
      if (userID === this.props.chatMemberships[i].id)
        return this.props.chatMemberships[i].colour;
    }
    return null;
  };
  handleMessageChange = async e => {
    await this.setState({
      message: e.currentTarget.value
    });
  };
  message = async () => {
    await this.setState({isSendingMessage: true});
    await this.props.message(this.state.message);
    await this.setState({
      message: "",
      isSendingMessage: false,
      isShowEmojiSelector: false
    });
    scrollToMostRecentMessage();
  };
  onKeyUp = e => {
    if (e.keyCode === 13)
      this.message();
  };
  toggleChatDetails = async () => {
    await this.setState({isShowChatDetails: !this.state.isShowChatDetails});
  };
  toggleEmojiSelector = async () => {
    await this.setState( {isShowEmojiSelector: !this.state.isShowEmojiSelector});
  };
  render() {
    return (
      <ContentContainer
        style={{
          width: "100%",
          marginTop: "60px",
          overflowY: "scroll",
          paddingBottom: "75px"
        }}
        id="chatMessageContainer"
      >
        <ChatTitle>
          <Flex>
            <ArrowBack
              size={32}
              onClick={() => this.props.setChatId(null)}
              style={{marginRight:"10px", cursor: "pointer", color: "#ffff00"}}
            />
            <DisplayLabel style={{color:"#ffff00"}}>{this.props.chatTitle}</DisplayLabel>
          </Flex>
          {
            this.state.isShowEmojiSelector
            &&
            <EmojiSelector
              addText={this.addText}
              toggleEmojiSelector={this.toggleEmojiSelector}
            />
          }
          <div
            style={{display:"flex", alignItems:"center", justifyContent:"flex-end", marginRight:"20px", cursor:"pointer"}}
            onClick={this.toggleChatDetails}
          >
            <div style={{fontSize:"1.2em", color: "#ffff00"}}>
              {
                this.props.chatMemberships.length === 0
                ?
                <span>Loading</span>
                :
                <span>{this.props.chatMemberships.length} {this.props.chatMemberships.length > 1 ? `people` : `person`}</span>
              }
            </div>
            <div>
              {
                this.state.isShowChatDetails
                ?
                <CaretUp size={32} style={{marginLeft:"10px", cursor: "pointer", color: "#ffff00"}} />
                :
                <CaretDown size={32} style={{marginLeft:"10px", cursor: "pointer", color: "#ffff00"}} />
              }
            </div>
          </div>
        </ChatTitle>
        {
          this.state.isShowChatDetails
          &&
          <ChatTitle top={`104px`} onClick={this.toggleChatDetails} style={{cursor:"pointer"}}>
            <div
              style={{display:"flex", alignItems:"center", justifyContent:"flex-end", marginRight:"20px"}}
            >
              <div />
              <div>
              {
                this.props.chatMemberships.map(
                  user => {
                    if (this.props.userID === user.id) {
                      return (
                        <ChatParticipant key={user.id} name={`Me`} colour={user.colour !== null ? user.colour : config.colours.lapisLazuli} />
                      )
                    }
                    else {
                      return (
                        <ChatParticipant key={user.id} name={`${user.firstName} ${user.lastName}`} colour={user.colour !== null ? user.colour : config.colours.oldRose} />
                      )
                    }
                  }
                )
              }
              </div>
            </div>
          </ChatTitle>
        }
        {
          this.props.chatMessages.length > 0
          &&
          this.props.chatMessages.map(message => {
            return (
              <Message
                key={message.id}
                message={message}
                getColourFromMessageUserID={this.getColourFromMessageUserID}
                token={this.props.token}
                userID={this.props.userID}
              />
            )
          })
        }
        <SendMessage>
          <div>
            <Smile size={27} style={{color:"#ffff00", marginRight:"20px", cursor: "pointer"}} onClick={this.toggleEmojiSelector} />
          </div>
          <MessageInput
            ref={(input) => { this.nameInput = input; }}
            onKeyUp={this.onKeyUp}
            value={this.state.message}
            type="text"
            disabled={this.state.isSendingMessage}
            onChange={this.handleMessageChange}
          />
          <Button
            onClick={this.message}
            disabled={this.state.isSendingMessage}
          >
            Send
          </Button>
        </SendMessage>
        <div id="messageContainerEnd" />
      </ContentContainer>
    )
  }
}

export default ChatMessageContainer;
