import React from "react";
import Button from "../shared/Button";

import { Check } from "styled-icons/boxicons-regular";

import data from "../../utilities/data";

class FinishedPage extends React.Component {
  componentDidMount = async () => {

    console.log("FinishedPage: about to send freeText", this.props.freeText);

    const emailResponse = await data.sendFeelingsDiaryRecord(
      this.props.token,
      this.props.feelings,
      this.props.freeText
    );
    console.log(emailResponse);
  };
  render() {
    return (
      <div>
        <div style={{textAlign: "center"}}>
          <Check size={130} style={{color: "#009900"}}/>
          <h3>
            All done!
          </h3>
          <h3>
            Your parents will get an email letting them know how you feel today.
          </h3>
        </div>
        <div style={{width: "100%", display: "flex", justifyContent: "center"}}>
          <Button
            onClick={this.props.closeFeelingsDiary}
            style={{minWidth: "200px", textAlign: "center"}}
          >
            Back to CatchUp
          </Button>
        </div>
      </div>
    );
  }
}

export default FinishedPage;
