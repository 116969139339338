import styled from "styled-components";
import React from 'react';

import { emojis, isSecretEmoji} from "../../utilities/processMessages";
import Flex from "../shared/Flex";

const EmojiSelectorContainer = styled.div`
  position: absolute;
  top: 52px;
  left: 0px;
  right: 0px;
  background-color: #ffffff;
  padding: 10px;
  padding-right: 30px;
  z-index: 10;
`;

const EmojiSelector = props => {
  return (
    <EmojiSelectorContainer>
      <Flex>
        <h3>Click an emoji to add to your message</h3>
        <div onClick={props.toggleEmojiSelector} style={{cursor: "pointer"}}>Close</div>
      </Flex>
      {
        emojis.map(
          emoji => {
            return (
              <span style={{cursor:"pointer"}} key={emoji.text} onClick={() => props.addText(emoji.text)}>
                {!isSecretEmoji(emoji.text) && `${emoji.text} `}
              </span>
            )
          }
        )
      }
    </EmojiSelectorContainer>
  )
}

export default EmojiSelector;
