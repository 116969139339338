import React from "react";
import config from '../../config';
import data from "../../utilities/data";
import { Smile, Frown } from "styled-icons/fa-regular";

import getTextColourFromBackgroundColour from "../../utilities/getTextColourFromBackgroundColour";
import MessageContainer from "../shared/MessageContainer";
import SmileyContainer from "../shared/SmileyContainer";

class Message extends React.Component {
  state = {
    messageRating: Number(this.props.message.messageRating)
  };
  handleSmileyClick = async smiley => {
    let newRating = 0;
    if (smiley === 'happy' && this.state.messageRating !== 1)
      newRating = 1;
    if (smiley === 'sad' && this.state.messageRating !== -1)
      newRating = -1;

    await this.setState({
      messageRating: newRating
    });

    await data.rateMessage(this.props.token, this.props.message.id, newRating);
  };
  getColours = () => {
    let backgroundColour = this.props.getColourFromMessageUserID(this.props.message.userID);
    if (backgroundColour === null) {
      if (this.props.message.userID === this.props.userID)
        backgroundColour = config.colours.lapisLazuli;
      else
        backgroundColour = config.colours.oldRose;
    }

    let textColour = getTextColourFromBackgroundColour(backgroundColour);

    return {
      backgroundColour: backgroundColour,
      textColour: textColour
    };
  };
  render() {
    const colours = this.getColours();
    const backgroundColour = colours.backgroundColour;
    const textColour = colours.textColour;
    return (
      <div
        key={this.props.message.id} style={{display: "flex", justifyContent:`${this.props.message.userID === this.props.userID ? 'flex-end' : 'flex-start'}`}}>
        <MessageContainer backgroundColour={backgroundColour} colour={textColour}>
          <div style={{fontWeight:"bold",marginBottom:"4px"}}>{this.props.message.firstName} {this.props.message.lastName}</div>
          {this.props.message.message}<br />
          <div style={{textAlign:"right",fontSize:"0.7em",marginTop:"7px"}}>
            {this.props.message.displayTime}
          </div>
        </MessageContainer>
        <SmileyContainer>
          <Smile size={34} onClick={() => this.handleSmileyClick('happy')} style={{cursor: "pointer", color: this.state.messageRating === 1 ? "#ffff00" : "#ababab"}} />
          <Frown size={34}  onClick={() => this.handleSmileyClick('sad')} style={{cursor: "pointer", color: this.state.messageRating === -1 ? "#454545" : "#ababab"}} />
        </SmileyContainer>
      </div>
    )
  }
}

export default Message;

