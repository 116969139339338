import React from 'react';
import { Check } from "styled-icons/boxicons-regular";

import config from "../../config";
import Button from "../shared/Button";
import Flex from "../shared/Flex";
import Input from "../shared/Input";
import DisplayLabel from "../shared/DisplayLabel";
import data from "../../utilities/data";
import AddChildProgressIndicator from "./AddChildProgressIndicator";
import Image from "../shared/Image";
import rainbow from "../../static/images/rainbow.png";
import MessageContainer from "../shared/MessageContainer";
import SmileyContainer from "../shared/SmileyContainer";
import {Frown, Smile} from "styled-icons/fa-regular";

class AddChild extends React.Component {
  state = {
    firstName: "",
    lastName: "",
    username: "",
    isCheckedForExistingUsername: false,
    isUsernameExists: false,
    password: "",
    stage: 1,
    isSaving: false,
    isSuccessful: false,
    completeMessage: "",
    isShowPasswordTips: false,
    messageRating: 0
  }
  componentDidMount() {
    console.log("AddChild mounted");
  }
  checkIfFormComplete = () => {
    let isCanProceed = false;
    if (
      this.checkIsNotBlank('firstName', 'Please enter a first name')
      &&
      this.checkIsNotBlank('lastName', 'Please enter a last name')
      &&
      this.checkIsNotBlank('username', 'Please enter a username')
      &&
      this.checkIsNotBlank('password', 'Please enter a password')
    ) {
      isCanProceed = true;
    }
    if (this.state.isUsernameExists) {
      alert("The username you have entered already exists. Please choose another.");
      isCanProceed = false;
    }
    return isCanProceed;
  }
  checkIfUsernameExists = async username => {
    console.log("Checking username " + this.state.username);
    const isUsernameExists = await data.isUsernameExists(this.props.token, this.state.username);
    console.log("isUsernameExistsResult", isUsernameExists);

    if (isUsernameExists[0].usernameCount > 0) {
      await this.setState({
        isCheckedForExistingUsername: true,
        isUsernameExists: true
      });
    }
    else {
      await this.setState({
        isCheckedForExistingUsername: true,
        isUsernameExists: false
      });
    }
  }
  getUsernameNote = async => {
    if (this.state.isCheckedForExistingUsername && this.state.isUsernameExists) {
      return (
        <div style={{color:"red", marginBottom: "5px"}}>
          This username is already in use - please choose another
        </div>
      )
    }
    if (this.state.isCheckedForExistingUsername && !this.state.isUsernameExists) {
      return (
        <div style={{color:"#669933", marginBottom: "5px"}}>
          <Check size={18} /> This username is available
        </div>
      )
    }
    return "";
  }
  handleChange = async e => {
    console.log("Change ", e.currentTarget.id);
    let a = {};
    a[e.currentTarget.id] = e.currentTarget.value;
    await this.setState(a);
  }
  handleSmileyClick = async smiley => {
    let newRating = 0;
    if (smiley === 'happy' && this.state.messageRating !== 1)
      newRating = 1;
    if (smiley === 'sad' && this.state.messageRating !== -1)
      newRating = -1;
    await this.setState({
      messageRating: newRating
    });
  };
  saveChild = async () => {
    const addChildResult = await data.addChild(this.props.token, this.state);
    await this.setState({
      isSuccessful: true,
      isSaving: false
    });
    console.log("Add child result", addChildResult);
  }
  togglePasswordTips = async () => {
    await this.setState({isShowPasswordTips: !this.state.isShowPasswordTips});
  };
  updateStage = async stage => {
    if (this.checkIfFormComplete() === true)
      await this.setState({
        isSaving: true,
        stage: stage
      });
    if (stage === 4) {
      this.saveChild();
    }
  };
  checkIsNotBlank = (field, errorMessage) => {
    if (this.state[field] === "") {
      alert (errorMessage);
      return false;
    }
    return true;
  }
  render() {
    if (this.state.stage === 1) {
      return (
        <div style={{paddingRight: "20px"}}>
          <AddChildProgressIndicator stage={1} updateStage={this.updateStage}/>

          <h3>Add child to my account.</h3>
          <DisplayLabel>First name</DisplayLabel>
          <Input
            id="firstName"
            value={this.state.firstName}
            onChange={this.handleChange}
          />
          <DisplayLabel>Last name</DisplayLabel>
          <Input
            id="lastName"
            value={this.state.lastName}
            onChange={this.handleChange}
          />
          <DisplayLabel>Username</DisplayLabel>
          <Input
            id="username"
            value={this.state.username}
            onBlur={this.checkIfUsernameExists}
            onChange={this.handleChange}
          />
          {this.getUsernameNote()}
          <p style={{marginTop: "0px"}}>
            Your child will use this for logging in.
            This username is also used to identify people your child can chat with.
          </p>
          <DisplayLabel>Password</DisplayLabel>
          <Input
            id="password"
            value={this.state.password}
            onChange={this.handleChange}
          />
          <p style={{marginTop: "0px"}}>
            Your child will be able to change this to something else.
          </p>
          <Flex>
            <Button onClick={this.props.toggleAddChildView} style={{width: "140px", textAlign: "center"}}>
              Cancel
            </Button>
            <Button onClick={() => this.updateStage(2)} style={{width: "140px", textAlign: "center"}}>
              Next
            </Button>
          </Flex>
        </div>
      )
    }
    if (this.state.stage === 2) {
      return (
        <div style={{paddingRight: "20px"}}>
          <AddChildProgressIndicator stage={2} updateStage={this.updateStage} />
          <h3>About your child's account</h3>
          <p>
            <strong>Chats</strong>
          </p>
          <ul>
            <li style={{marginBottom: "10px"}}>To start with, your child will have no chats.</li>
            <li style={{marginBottom: "10px"}}><strong style={{color:"red"}}>They will never be able to create a chat with anyone new without your permission.</strong></li>
            <li style={{marginBottom: "10px"}}>
              Soon, we will be introducing a 'chat request' system where you can send a request to one or more parents to create a chat between your children.
              All parents will need to agree before the chat is created.
            </li>
            <li style={{marginBottom: "10px"}}>
              In the meantime please contact Chris at support@catchupglobal.com to create a new chat.
            </li>
          </ul>
          <p>
            <strong>About the CatchUp Prototype</strong>
          </p>
          <ul>
            <li style={{marginBottom: "10px"}}>CatchUp is an early prototype app which is still in development.</li>
            <li style={{marginBottom: "10px"}}>Features may change and constant access to CatchUp is not guaranteed.</li>
          </ul>
          <p>
            <strong>Your online security</strong>
          </p>
          <ul>
            <li style={{marginBottom: "10px"}}>
              The CatchUp prototype uses strong encryption and includes reasonable measures to protect your security.
              However, it does not provide the same level of end-to-end security as WhatsApp and other chat tools.
            </li>
            <li style={{marginBottom: "10px"}}>
              Remember you can change your password in the 'My Account' area.
            </li>
            <li style={{marginBottom: "10px"}}>Do not tell anyone your password.</li>
            <li style={{marginBottom: "10px"}}>
              After logging in to CatchUp, you will stay logged in for 24 hours unless you log out.
              This means that someone else could access your CatchUp account if they use your device in this time.
            </li>
            <p style={{textAlign: "right", cursor: "pointer"}} onClick={this.togglePasswordTips}>More password tips</p>
            {
              this.state.isShowPasswordTips
              &&
              <div>
                <li style={{marginBottom: "10px"}}>
                  It's best to use a password which has not been used anywhere before.
                </li>
                <li style={{marginBottom: "10px"}}>
                  Longer passwords are stronger passwords - ideally more than 10 characters.
                </li>
                <li style={{marginBottom: "10px"}}>
                  Avoid recognisable words in your password.
                </li>
                <li style={{marginBottom: "10px"}}>
                  Don't just replace E with 3 and L with 1 etc - this slows down hackers a bit, but not much
                </li>
                <li style={{marginBottom: "10px"}}>
                  Passwords that are so long and strange that you need to write them down are weak passwords.
                </li>
              </div>
            }
          </ul>
          <p>
            <strong>The Legal Bit</strong>
          </p>
          <ul>
            <li style={{marginBottom: "10px"}}>Use of the CatchUp prototype is provided free of charge entirely at the user's own risk.</li>
            <li style={{marginBottom: "10px"}}>We will keep all data strictly confidential except where required by law.</li>
            <li style={{marginBottom: "10px"}}>Contact support@catchupglobal.com if you have any queries, or would like to cancel your account and remove all data.</li>
            <li style={{marginBottom: "10px"}}>The CatchUp prototype is made by Charlbury Technology Limited, a company registered in England No. 10744709.</li>
          </ul>
          <Flex>
            <Button onClick={this.props.toggleAddChildView} style={{width: "140px", textAlign: "center"}}>
              Cancel
            </Button>
            <Button onClick={() => this.updateStage(3)} style={{width: "140px", textAlign: "center"}}>
              I agree
            </Button>
          </Flex>
        </div>
      )
    }
    if (this.state.stage === 3) {
      return (
        <div style={{paddingRight: "20px"}}>
          <AddChildProgressIndicator stage={3} updateStage={this.updateStage} />
          <p>Please ask your child to read this with you</p>
          <hr />
          <h3>
            <ol>
              <li style={{marginBottom:"10px"}}>My parent is reading this with me.</li>
              <li style={{marginBottom:"10px"}}>I will be able to use CatchUp to send text messages and emojis to my friends.</li>
              <li style={{marginBottom:"10px"}}>Parents won't be able to read these messages.</li>
              <li style={{marginBottom:"10px"}}>I promise that I will not write anything that might seem unkind, nasty or rude.</li>
              <li style={{marginBottom:"10px"}}>Chat messages look like this:</li>
            </ol>
          </h3>

          <div style={{display: "flex", justifyContent: "flex-start", paddingLeft: "30px"}}>
            <MessageContainer backgroundColour={config.colours.lapisLazuli} colour={`#ffffff`}>
              <div style={{fontWeight:"bold",marginBottom:"4px"}}>{this.state.firstName} {this.state.lastName}</div>
              Your message will appear here.<br />
              <div style={{textAlign:"right",fontSize:"0.7em",marginTop:"7px"}}>
                Just now
              </div>
            </MessageContainer>
            <SmileyContainer>
              <Smile size={34} onClick={() => this.handleSmileyClick('happy')} style={{cursor: "pointer", color: this.state.messageRating === 1 ? "#ffff00" : "#ababab"}} />
              <Frown size={34}  onClick={() => this.handleSmileyClick('sad')} style={{cursor: "pointer", color: this.state.messageRating === -1 ? "#454545" : "#ababab"}} />
            </SmileyContainer>
          </div>

          <h3>
            <ol start={6}>
              <li style={{marginBottom: "10px"}}>
                If a message makes you happy or sad, you can press the faces next to the message (try this now).
                Your parents will be told if you press the happy or sad faces more than normal.
              </li>
              <li style={{marginBottom: "10px"}}>
                There is also a 'Feelings Diary' if you want to record how you are feeling.
                Anything you enter will be emailed to your parents once you are finished.
              </li>
              <li style={{marginBottom: "10px"}}>
                You can add emojis to your messages by including some words in (brackets).
                For example, if you type (rainbow) this will turn into <Image src={rainbow} size={48} style={{marginBottom:"-10px"}} /> when you send it.
              </li>
            </ol>
          </h3>

          <Flex>
            <Button onClick={this.props.toggleAddChildView} style={{width: "140px", textAlign: "center"}}>
              Cancel
            </Button>
            <Button onClick={() => this.updateStage(4)} style={{width: "140px", textAlign: "center"}}>
              I agree
            </Button>
          </Flex>
        </div>
      )
    }
    if (this.state.stage === 4) {
      return (
        <div style={{paddingRight: "20px"}}>
          <AddChildProgressIndicator stage={4} updateStage={this.props.toggleAddChildView} />
          {
            this.state.isSaving
            &&
            <div style={{textAlign: "center"}}>
              <p>
                Saving
              </p>
            </div>
          }
          {
            (this.state.isSaving === false && this.state.isSuccessful)
            ?
            <div>
              <div style={{textAlign: "center"}}>
                <Check size={130} style={{color: "#009900"}}/>
                <h3>
                  All done!
                </h3>
                <h3>
                  Your child {this.state.firstName} has been added to the system.
                </h3>
                <p>
                  Remember to start with your child will not have any chats.
                  Contact support@catchupglobal.com to set up chats.
                </p>
              </div>
                <div style={{width: "100%", display: "flex", justifyContent: "center"}}>
                <Button
                onClick={this.props.toggleAddChildView}
                style={{minWidth: "200px", textAlign: "center"}}
                >
                Back to CatchUp
                </Button>
              </div>
            </div>
            :
            <div>
              {this.state.completeMessage}
            </div>
          }
        </div>
      )
    }
  }
}

export default AddChild;
