import React from "react";
import {ArrowBack} from "styled-icons/boxicons-regular";

import ContentContainer from "../shared/ContentContainer";
import DisplayLabel from "../shared/DisplayLabel";
import HorizontalRule from "../shared/HorizontalRule";

import feelings from "./feelings";
import FeelingsDiarySelectorPage from "./FeelingsDiarySelectorPage";
import FeelingsDiaryHomePage from "./FeelingsDiaryHomePage";
import FeelingsDetail from "./FeelingsDetail";
import FreeText from "./FreeText";
import FinishedPage from "./FinishedPage";

class FeelingsDiary extends React.Component {
  state = {
    isLoading: false,
    isRegularCheck: false,
    feelings: [],
    freeText: "",
    currentPage: "homepage" // homepage, feelingSelector, feelingDetail, freeText, finishedPage
  };
  componentDidMount = async () => {
    this.prepareFeelings(feelings);
  };
  closeFeelingsDiary = () => {
    this.props.setChatId(null);
  };
  goBack = async () => {
    if (this.state.currentPage === 'homepage')
      this.closeFeelingsDiary();
    if (this.state.currentPage === 'feelingSelector')
      this.showPage('homepage');
    if (this.state.currentPage === 'freeText')
      this.showPage('homepage');
  };
  showPage = async page => { await this.setState({ currentPage: page }); };
  prepareFeelings = async feelings => {
    for (let i = 0; i < feelings.length; i++) {
      feelings[i].isSelected = false;
      feelings[i].intensity = 3;
      feelings[i].answers = ["","",""];
    }
    await this.setState({feelings: feelings});
  };
  toggleSelectFeeling = async feelingID => {
    let updatedFeelings = this.state.feelings;
    for (let i = 0; i < updatedFeelings.length; i++) {
      if (updatedFeelings[i].id === feelingID) {
        updatedFeelings[i].isSelected = !updatedFeelings[i].isSelected;
      }
    }
    await this.setState({feelings: updatedFeelings});
  };
  updateFreeText = async e => {
    const newFreeText = e.currentTarget.value;
    await this.setState({freeText: newFreeText});
  };
  render = () => {
    return (
      <ContentContainer>
        {
          this.state.isShowFeelingsDiaryHomePage
          &&
          <FeelingsDiaryHomePage showFeelingSelector={this.showFeelingSelector} />
        }
        {
          (this.state.currentPage === 'homepage' || this.state.currentPage === 'feelingSelector' || this.state.currentPage === 'freeText')
          &&
            <React.Fragment>
              <DisplayLabel>
                <ArrowBack
                  size={32}
                  onClick={this.goBack}
                  style={{marginRight:"10px", cursor: "pointer"}}
                />
                {this.state.currentPage === 'homepage' && 'My Feelings Diary'}
                {this.state.currentPage === 'feelingSelector' && 'How do you feel?'}
              </DisplayLabel>
              <HorizontalRule />
            </React.Fragment>
        }
        {
          this.state.currentPage === 'homepage'
          &&
          <FeelingsDiaryHomePage
            showPage={this.showPage}
            isParent={this.props.isParent}
          />
        }
        {
          this.state.currentPage === 'feelingSelector'
          &&
          <FeelingsDiarySelectorPage
            feelings={this.state.feelings}
            toggleSelectFeeling={this.toggleSelectFeeling}
            showPage={this.showPage}
          />
        }
        {
          this.state.currentPage === 'feelingDetail'
          &&
          <FeelingsDetail
            feelings={this.state.feelings}
            showPage={this.showPage}
          />
        }
        {
          this.state.currentPage === 'freeText'
          &&
          <FreeText
            freeText={this.state.freeText}
            updateFreeText={this.updateFreeText}
            showPage={this.showPage}
          />
        }

        {
          this.state.currentPage === 'finishedPage'
          &&
          <FinishedPage
            token={this.props.token}
            closeFeelingsDiary={this.closeFeelingsDiary}
            feelings={this.state.feelings}
            freeText={this.state.freeText}
          />
        }
      </ContentContainer>
    )
  }
}

export default FeelingsDiary;
