import React from 'react';
import reactStringReplace from 'react-string-replace';

import { Car } from "styled-icons/fa-solid/Car";
import { Dog, Hamburger } from "styled-icons/fa-solid";
import { GrinTears, BasketballBall, HeadphonesAlt, Broom, Lemon } from "styled-icons/fa-solid";
import { Smile, Frown, Angry, LaughWink, Meh } from "styled-icons/fa-regular";
import { Swim, Cycling, CloudLightning } from "styled-icons/boxicons-regular";
import { PlaneAlt, Star, TennisBall, Planet, PlaneLand } from "styled-icons/boxicons-solid";
import { Poo, Cat, Running, Walking, Skull } from "styled-icons/fa-solid";
import { SpoonKnife, Sleepy2, Music } from "styled-icons/icomoon";
import { Drink } from "styled-icons/boxicons-solid";

import Image from "../components/shared/Image";

import alien from "../static/images/alien.png";
import butterfly from "../static/images/butterfly.png";
import canadamap from "../static/images/canadamap.png";
import captainshat from "../static/images/captainshat.png";
import creeper from "../static/images/creeper.png";
import doughnut from "../static/images/doughnut.png";
import elephant from "../static/images/elephant.png"
import firetruck from "../static/images/firetruck.png";
import football from "../static/images/football.png";
import footballboots from "../static/images/footballboots.png";
import goal from "../static/images/goal.png";
import icecream from "../static/images/icecream.png";
import jewel from "../static/images/jewel.png";
import kanga from "../static/images/kanga.png";
import lipstick from "../static/images/lipstick.png";
import lollipop from "../static/images/lollipop.png";
import minecraftsword from "../static/images/minecraftsword.png";
import nailpaint from "../static/images/nailpaint.png";
import pizza from "../static/images/pizza.png";
import rainbow from "../static/images/rainbow.png";
import rosie from "../static/images/rosie.png";
import shootingstar from "../static/images/shootingstar.png";
import skates from "../static/images/skates.png";
import shark from "../static/images/shark.png";
import sun from "../static/images/sun.png";
import unicorn from "../static/images/unicorn.png";
import watermelon from "../static/images/watermelon.png";
import worldcup from "../static/images/worldcup.png";

const replacements = [
  { text: "(alien)", component: (match, i) => ( <Image src={alien} key={match+i} size={76} /> ) },
  { text: "(angry)", component: (match, i) => ( <Angry key={match+i} size={24} /> ) },
  { text: "(basketball)", component: (match, i) => ( <BasketballBall key={match+i} size={24} /> ) },
  { text: "(bigpoo)", component: (match, i) => ( <Poo key={match+i} size={128} style={{color:"brown"}}/> ) },
  { text: "(butterfly)", component: (match, i) => ( <Image src={butterfly} key={match+i} size={76} /> ) },
  { text: "(canadamap)", component: (match, i) => ( <Image src={canadamap} key={match+i} style={{width:"100%", maxWidth:"600px"}} /> ) },
  { text: "(captainshat)", component: (match, i) => ( <Image src={captainshat} key={match+i} size={76} /> ) },
  { text: "(cat)", component: (match, i) => ( <Cat key={match+i} size={24} /> ) },
  { text: "(creeper)", component: (match, i) => ( <Image src={creeper} key={match+i} size={76} /> ) },
  { text: "(cycling)", component: (match, i) => ( <Cycling key={match+i} size={24} /> ) },
  { text: "(dog)", component: (match, i) => ( <Dog key={match+i} size={24} /> ) },
  { text: "(doughnut)", component: (match, i) => ( <Image src={doughnut} key={match+i} size={76} /> ) },
  { text: "(elephant)", component: (match, i) => ( <Image src={elephant} key={match+i} size={76} /> ) },
  { text: "(firetruck)", component: (match, i) => ( <Image src={firetruck} key={match+i} size={76} /> ) },
  { text: "(football)", component: (match, i) => ( <Image src={football} key={match+i} size={76} /> ) },
  { text: "(footballboots)", component: (match, i) => ( <Image src={footballboots} key={match+i} size={76} /> ) },
  { text: "(gertie)", component: (match, i) => ( <PlaneAlt key={match+i} size={24} /> ) },
  { text: "(geronimo)", component: (match, i) => ( <PlaneLand key={match+i} size={24} /> ) },
  { text: "(goal)", component: (match, i) => ( <Image src={goal} key={match+i} size={76} /> ) },
  { text: "(hamburger)", component: (match, i) => ( <Hamburger key={match+i} size={24} /> ) },
  { text: "(happy)", component: (match, i) => ( <Smile key={match+i} size={24} /> ) },
  { text: "(headphones)", component: (match, i) => ( <HeadphonesAlt key={match+i} size={24} /> ) },
  { text: "(icecream)", component: (match, i) => ( <Image src={icecream} key={match+i} size={76} /> ) },
  { text: "(jewel)", component: (match, i) => ( <Image src={jewel} key={match+i} size={76} /> ) },
  { text: "(laugh)", component: (match, i) => (  <LaughWink key={match+i} size={24} /> ) },
  { text: "(laughcry)", component: (match, i) => ( <GrinTears key={match+i} size={24} /> ) },
  { text: "(lightning)", component: (match, i) => ( <CloudLightning key={match+i} size={24} /> ) },
  { text: "(lipstick)", component: (match, i) => ( <Image src={lipstick} key={match+i} size={76} /> ) },
  { text: "(lollipop)", component: (match, i) => ( <Image src={lollipop} key={match+i} size={76} /> ) },
  { text: "(kanga)", component: (match, i) => ( <Image src={kanga} key={match+i} size={76} /> ) },
  { text: "(meh)", component: (match, i) => ( <Meh key={match+i} size={24} /> ) },
  { text: "(minecraftsword)", component: (match, i) => ( <Image src={minecraftsword} key={match+i} size={76} /> ) },
  { text: "(mocktail)", component: (match, i) => ( <Drink key={match+i} size={24} /> ) },
  { text: "(music)", component: (match, i) => ( <Music key={match+i} size={24} /> ) },
  { text: "(nailpaint)", component: (match, i) => ( <Image src={nailpaint} key={match+i} size={76} /> ) },
  { text: "(saturn)", component: (match, i) => ( <Planet key={match+i} size={24} /> ) },
  { text: "(pizza)", component: (match, i) => ( <Image src={pizza} key={match+i} size={76} /> ) },
  { text: "(plane)", component: (match, i) => ( <PlaneAlt key={match+i} size={24} /> ) },
  { text: "(poo)", component: (match, i) => ( <Poo key={match+i} size={24} /> ) },
  { text: "(rainbow)", component: (match, i) => ( <Image src={rainbow} key={match+i} size={76} /> ) },
  { text: "(rosie)", component: (match, i) => ( <Image src={rosie} key={match+i} size={76} /> ) },
  { text: "(running)", component: (match, i) => ( <Running key={match+i} size={24} /> ) },
  { text: "(sad)", component: (match, i) => ( <Frown key={match+i} size={24} /> ) },
  { text: "(shark)", component: (match, i) => ( <Image src={shark} key={match+i} size={76} /> ) },
  { text: "(shootingstar)", component: (match, i) => ( <Image src={shootingstar} key={match+i} size={76} /> ) },
  { text: "(skates)", component: (match, i) => ( <Image src={skates} key={match+i} size={76} /> ) },
  { text: "(skull)", component: (match, i) => ( <Skull key={match+i} size={24} /> ) },
  { text: "(sleepy)", component: (match, i) => ( <Sleepy2 key={match+i} size={24} /> ) },
  { text: "(star)", component: (match, i) => ( <Star key={match+i} size={24} /> ) },
  { text: "(sun)", component: (match, i) => ( <Image src={sun} key={match+i} size={76} /> ) },
  { text: "(sweepforassassins)", component: (match, i) => ( <Broom key={match + i} size={24} /> ) },
  { text: "(swim)", component: (match, i) => ( <Swim key={match+i} size={24} /> ) },
  { text: "(tennis)", component: (match, i) => ( <TennisBall key={match+i} size={24} /> ) },
  { text: "(travellinglemon)", component: (match, i) => ( <Lemon key={match + i} size={24} style={{color:"#ffff00"}} /> ) },
  { text: "(unicorn)", component: (match, i) => ( <Image src={unicorn} key={match+i} size={76} /> ) },
  { text: "(yellowcar)", component: (match, i) => ( <Car key={match+i} style={{color:"#ffff00"}} size={24} /> ) },
  { text: "(walking)", component: (match, i) => ( <Walking key={match+i} size={24} /> ) },
  { text: "(watermelon)", component: (match, i) => ( <Image src={watermelon} key={match+i} size={76} /> ) },
  { text: "(worldcup)", component: (match, i) => ( <Image src={worldcup} key={match+i} size={76} /> ) },
  { text: ":-)", component: (match, i) => ( <Smile key={match+i} size={24} /> ) },
  { text: ":-(", component: (match, i) => ( <Frown key={match+i} size={24} /> ) },
  {
    text: "(meal)",
    component: (match, i) => (
      <div key={match+i} style={{width: "100%", fontSize:"1.4em", display: "flex", justifyContent: "space-between"}}>
        <div style={{marginRight: "20px"}}>
          <SpoonKnife size={48} />
        </div>
        <div>Got to go for a meal. Speak to you later!</div>
      </div>
    )
  },
  {
    text: "(parentsaysnomore)",
    component: (match, i) => (
      <div key={match+i} style={{width: "100%", fontSize:"1.4em", display: "flex", justifyContent: "space-between"}}>
        <div style={{marginRight: "20px"}}>
          <Angry size={48} />
        </div>
        <div>Parent just told me I need to get off Catchup. Speak to you later!</div>
      </div>
    )
  }
];

const processMessage = message => {
  for (let a = 0; a < replacements.length; a++) {
    message = reactStringReplace(message, replacements[a].text, replacements[a].component);
  }

  return message;
};

export const processChatData = (chatData) => {
  for(let i = 0; i <  chatData.length; i++) {
    let newMessage = chatData[i].message;
    newMessage = processMessage(newMessage);
    chatData[i].message = newMessage;
  }
  return chatData;
};

export const processChats = (chats) => {
  for(let i = 0; i < chats.length; i++) {
    let newLastMessage = chats[i].lastMessage;
    newLastMessage = processMessage(newLastMessage);
    chats[i].lastMessage = newLastMessage;
  }
  return chats;
};

const secretEmojis = [
  '(bigpoo)',
  '(gertie)',
  '(sweep)',
  '(parentsaysnomore)',
  '(canadamap)',
  '(meal)',
  '(sweepforassassins)',
  '(travellinglemon)',
  '(geronimo)'
];

export const isSecretEmoji = emoji => {
  for (let i = 0; i < secretEmojis.length; i++) {
    if (emoji === secretEmojis[i])
      return true;
  }
  return false;
}

export const emojis = replacements;
