import styled from 'styled-components';

const BoxContentContainer = styled.div`
  margin: 20px;
  padding: 10px;
  border: 1px solid #676767;
  background-color: #dedede;
  border-radius: 7px;
`;

export default BoxContentContainer;
