import React from 'react';

import { Check } from "styled-icons/fa-solid";
import { Smile } from 'styled-icons/boxicons-solid';

import Flex from "../shared/Flex";

const getIcon = (stageIndicator, currentStage) => {
  if (stageIndicator < currentStage) {
    return (
      <Check size={32} style={{color: '#669933'}}/>
    );
  }

  if (stageIndicator === currentStage) {
    return (
      <Smile size={32} style={{color: '#343434'}}/>
    );
  }

  if (stageIndicator > currentStage) {
    return (
      <Smile size={32} style={{color: '#ababab'}}/>
    );
  }

}

const AddChildProgressIndicator = props => {
  return (
    <div style={{paddingBottom:"10px", borderBottom:"1px solid #343434"}}>
      <Flex style={{justifyContent:"space-around"}}>
        <Flex column style={{width:"30%"}}>
          <div>
            {getIcon(1, props.stage)}
          </div>
          <div style={{fontSize:"0.8em"}}>
            1. Enter details
          </div>
        </Flex>
        <Flex column style={{width:"30%"}}>
          <div>
            {getIcon(2, props.stage)}
          </div>
          <div style={{fontSize:"0.8em"}}>
            2. Parent agreement
          </div>
        </Flex>
        <Flex column style={{width:"30%"}}>
          <div>
            {getIcon(3, props.stage)}
          </div>
          <div style={{fontSize:"0.8em"}}>
            3. Child agreement
          </div>
        </Flex>
      </Flex>
    </div>
  )
}

export default AddChildProgressIndicator;
