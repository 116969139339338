import React from "react";


class ChildInfoContainer extends React.Component {
  componentDidMount = async () => {
    console.log("Child Info Container", this.props.childData);
  };
  render() {
    return (
      <div>
        <h4>Chats</h4>
        <p>We are working on new features to make it easier for you to create and manage chats for your children.</p>
        <p>{this.props.childData.firstName} is a member of {this.props.childData.chats.length} {this.props.childData.chats.length === 1 ? `chat` : `chats`}</p>
        {
          this.props.childData.chats.map(chat => {
            return (
              <p key={chat.id}>
                <strong>{chat.name}</strong><br />
                {chat.people.map(person => {
                  return (
                    <span key={person.id}>{person.firstName} {person.lastName}<br /></span>
                  )
                })}
              </p>
            )
          })
        }
        <hr />
        <p>
          To remove a child from your account and delete all data, please contact support@catchupglobal.com
        </p>
      </div>
    )
  }
}

export default ChildInfoContainer;
