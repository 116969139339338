import React from "react";

import FeelingsContainer from "./FeelingsContainer";
import FeelingSelector from "./FeelingSelector";
import Button from "../shared/Button";

const FeelingsDiarySelectorPage = props => {
  return (
    <div>
      <h3>Select all of the feelings you have at the moment</h3>
      <FeelingsContainer>
        {
          props.feelings.map(feeling => {
            return (
              <FeelingSelector
                key={feeling.id}
                isSelected={feeling.isSelected}
                id={feeling.id}
                name={feeling.name}
                emoji={feeling.emoji}
                toggleSelectFeeling={props.toggleSelectFeeling}
              />
            )
          })
        }
      </FeelingsContainer>
      <div style={{width: "100%", display:"flex", justifyContent:"center"}}>
        <Button
          onClick={() => props.showPage(`feelingDetail`)}
          style={{minWidth:"200px", textAlign: "center"}}
        >
          Next
        </Button>
      </div>
    </div>
  )
};

export default FeelingsDiarySelectorPage;
