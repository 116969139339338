import React from "react";

import Button from "../shared/Button";
import FeelingsDetailTextAreInput from "./FeelingsDetailTextAreInput";

class FreeText extends React.Component {
  render() {
    return (
      <div>
        <div>
          <p>What other thoughts might be useful to share with your parents?</p>
          <FeelingsDetailTextAreInput
            onChange={this.props.updateFreeText}
            value={this.props.freeText}
          />
        </div>
        <div style={{width: "100%", display: "flex", justifyContent: "center"}}>
          <Button
            onClick={() => this.props.showPage('finishedPage')}
            style={{minWidth: "200px", textAlign: "center"}}
          >
            Next
          </Button>
        </div>
      </div>
    );
  }
}

export default FreeText;
