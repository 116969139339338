import React from "react";

import Image from "../components/shared/Image";

import worried from '../static/icons/worried.png';
import sad from '../static/icons/sad.png';
import lonely from '../static/icons/lonely.png';

import angry from '../static/icons/angry.png';
import guilty from '../static/icons/guilty.png'
import irritated from '../static/icons/irritated.png';

import bored from '../static/icons/bored.png';
import proud from '../static/icons/proud.png';
import love from '../static/icons/love.png';

import grateful from '../static/icons/gratitude.png';
import happy from '../static/icons/happy.png';
import jealous from '../static/icons/jealous.png';

const getFeelingEmoji = (emoji, size) => {
  const imageSize = size;

  if (emoji === 'worried')
    return <Image src={worried} size={imageSize} />;
  if (emoji === 'sad')
    return <Image src={sad} size={imageSize} />;
  if (emoji === 'lonely')
    return <Image src={lonely} size={imageSize} />;

  if (emoji === 'angry')
    return <Image src={angry} size={imageSize} />;
  if (emoji === 'guilty')
    return <Image src={guilty} size={imageSize} />;
  if (emoji === 'irritated')
    return <Image src={irritated} size={imageSize} />;

  if (emoji === 'bored')
    return <Image src={bored} size={imageSize} />;
  if (emoji === 'proud')
    return <Image src={proud} size={imageSize} />;
  if (emoji === 'love')
    return <Image src={love} size={imageSize} />;

  if (emoji === 'grateful')
    return <Image src={grateful} size={imageSize} />;
  if (emoji === 'happy')
    return <Image src={happy} size={imageSize} />;
  if (emoji === 'jealous')
    return <Image src={jealous} size={imageSize} />;
};

export default getFeelingEmoji;
