import styled from "styled-components";
import config from "../../config";

const MyFeelingsDiaryProceedButton = styled.div`
  width: 200px;
  height: 200px;
  border-radius: 70px;
  color: #ffffff;
  background-color: ${config.colours.taupeGray};
  cursor: pointer;
  display: flex;
  flex-direction: column;
  margin-top: 50px;
  justify-content: center;
  align-items: center;
  
  &: hover {
    background-color: ${config.colours.lapisLazuli};
  }
`;

export default MyFeelingsDiaryProceedButton;
