import styled from "styled-components";

const MessageContainer = styled.div`
  padding: 7px;
  border-radius: 7px;
  text-align: left;
  background-color: ${props => props.backgroundColour ? props.backgroundColour : '#cdcdcd'};
  color: ${props => props.colour ? props.colour : '#ffffff'};
  margin: 5px 5px;
  max-width: 75%;
`;

export default MessageContainer;
