import styled from 'styled-components';

const Menu = styled.div`
  z-index: 100;
  max-width: 300px;
  position: fixed;
  right: 1px;
  top: 52px;
  border: none;
  padding: 5px 12px 12px 12px;
  background-color: white;
`;

export default Menu;
