import React from 'react';

import Button from './Button';
import ContentContainer from '../shared/ContentContainer';
import DisplayLabel from '../shared/DisplayLabel';
import Flex from "../shared/Flex";
import BoxContentContainer from "./BoxContentContainer";
import Input from '../shared/Input';
import { Smile, Frown } from "styled-icons/fa-regular";

class LoginPage extends React.Component {
  state = {
    username: "",
    password: ""
  };
  handleUpdateUsername = e => { this.setState({username: e.currentTarget.value}); };
  handleUpdatePassword = e => { this.setState({password: e.currentTarget.value}); };
  render() {
    return (
      <ContentContainer>
        <BoxContentContainer>
          <div style={{marginTop:"10px"}}>
            <Flex style={{justifyContent:"flex-start", marginBottom:"7px"}}>
              <Smile size={30} style={{color:"#ffff00", marginRight:"10px"}} /> Click this if a message makes you happy<br />
            </Flex>
            <Flex style={{justifyContent:"flex-start"}}>
              <Frown size={30}  style={{color:"#454545", marginRight:"10px"}} /> Click this if a message makes you sad<br />
            </Flex>
          </div>
        </BoxContentContainer>
        <BoxContentContainer>
            <p>Welcome to Catchup - a messaging service for 7-13 year olds.</p>
            <p>Your parents cannot read your messages, but they can see which chats you belong to and if you press the happy or sad faces more often than normal.</p>
        </BoxContentContainer>

        <div style={{margin: "20px"}}>
          <DisplayLabel>Username</DisplayLabel>
          <Input
            id="catchupUsername"
            value={this.state.username}
            onChange={this.handleUpdateUsername}
          />
          <DisplayLabel>Password</DisplayLabel>
          <Input
            id="catchupPassword"
            type="password"
            value={this.state.password}
            onChange={this.handleUpdatePassword}
          />
        </div>
        <div style={{textAlign:"center"}}>
          <Button onClick={() => this.props.authenticate(this.state.username, this.state.password)}>
            {this.props.isLoggingIn ? `Logging in...` : `Sign In`}
          </Button>
        </div>
        <div style={{textAlign: "center", marginTop: "5px"}}>
          {
            this.props.isLoginError
            &&
            <span style={{color:"red"}}>Please check your username and password and try again</span>
          }
        </div>
      </ContentContainer>
    )
  }
}

export default LoginPage;
