
const colours = {
  maastrichtBlue: "#011627",
  babyBlue: "#8ec4f0",
  pastelGray: "#cfd1c9",
  white: "#ffffff",
  ruddyPink: "#f09392"
};

const config = {
  colours: {
    background: '#ffdd00', //cdcdcd
    text: '#232323',
    lightBlue: colours.babyBlue,
    highlight: colours.ruddyPink,
    pastelGray: colours.pastelGray,
    taupeGray: '#878787',
    englishLavender: '#a08794',
    oldRose: '#bb7e8c',
    paleSilver: '#c9b6be',
    thistle: '#d1becf',
    lapisLazuli: '#2374ab',
    khakiGreen: '#7e805d',
    parentNote: '#ffecb3'
  },
  lightColours: [
    "#ffcdd2", "#f8bbd0", "#e1bee7", "#d1c4e9", "#c5cae9",
    "#bbdefb", "#b3e5fc", "#b2ebf2", "#b2dfdb", "#c8e6c9",
    "#dcedc8", "#f0f4c3", "#fff9c4", "#ffecb3", "#ffe0b2",
    "#ffccbc", "#d7ccc8", "#cfd8dc", "#ffffff", "#d9d9d9",
    "#dce775", "#fff176", "#ffeb3b"
  ],
  fonts: {
    display: "Arial",
    secondaryDisplay: "Arial"
  },
  checkForNewMessageIntervalMilliseconds: 3000
};

export default config;
