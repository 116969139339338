import React from "react";

import { ThumbsUp } from "styled-icons/fa-solid";

import MyFeelingsDiaryProceedButton from "./MyFeelingsDiaryProceedButton";
import ParentNote from "../shared/ParentNote";

const FeelingsDiaryHomePage = props => {
  return (
    <div>
      <h1>My Feelings Diary</h1>
      {
        props.isParent
        &&
        <ParentNote>
          <h3 style={{marginTop:"0px"}}>Parent notes</h3>
          <p>This is a tool where your child can identify feelings they are experiencing and share them with you.</p>
          <p>Once they have done this, you (and any other parent on the account) will be sent an email with the information they have entered.</p>
          <p>
            In this prototype no data is stored in our database and the email you receive will be the only record.
          </p>
          <p>
            <strong>
              The text below shows what your child will see.
              You can go through the process yourself and receive an email with a summary of your answers.
            </strong>
          </p>
        </ParentNote>
      }

      <h3>Let your parents know how you are feeling at the moment.</h3>
      <h3>Once you have finished, your answers will be sent to your parents, but nobody else will be able to see this.</h3>

      <div style={{width: "100%", display:"flex", justifyContent:"center"}}>
        <MyFeelingsDiaryProceedButton
          onClick={() => props.showPage('feelingSelector')}
        >
          <ThumbsUp size={96} /><br />
          Start
        </MyFeelingsDiaryProceedButton>
      </div>
    </div>
  )
};

export default FeelingsDiaryHomePage;
