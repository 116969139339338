import React from 'react';
import styled from 'styled-components';
import { UserCircle } from "styled-icons/fa-regular";

import config from '../../config';
import ContentContainer from '../shared/ContentContainer';
import DisplayLabel from "../shared/DisplayLabel";
import ListItem from "../shared/ListItem";
import UserIconContainer from "../shared/UserIconContainer";

const Chat = styled.div`
  padding: 5px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  
  &:hover {
    background-color: #ababab;
  }
`;

const ChatInfo = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 4px;
  width: 100%;
  border-bottom: 1px solid #676767;
`;

const LastMessage = styled.div`
  color: ${props => props.unread ? 'blue' : 'black'}
  font-size: 0.8em;
  margin-top: 4px;
`;

const ChatTimeDisplay = styled.div`
  font-size: 0.9em;
`;

class ChatContainer extends React.Component {
  render() {
    return (
      <ContentContainer>
        {
          this.props.isLoadingChats === true
          &&
          <div style={{textAlign:"center"}}>
            <DisplayLabel>
              Loading catchups
            </DisplayLabel>
          </div>
        }
        {
          this.props.chats.length < 1 && !this.props.isLoadingChats
          &&
          <div>
            <p>You are not a member of any chats.</p>
            <p>We are working on features that will enable you to create chats, but in the meantime please contact support@catchupglobal.com</p>
          </div>
        }
        {this.props.chats.map(chat => {
          return (
            <ListItem key={chat.id} onClick={() => {this.props.setChatId(chat.id)}}>
              <UserIconContainer />
              <ChatInfo>
                <div>
                  <div>{chat.name}</div>
                  <LastMessage unread={chat.unviewed > 0}>
                    {
                      chat.unviewed > 0
                      &&
                      <strong style={{marginRight:"4px"}}>
                        {chat.unviewed} new message{chat.unviewed !== 1 && 's'}
                      </strong>
                    }
                    {chat.lastMessage}
                  </LastMessage>
                </div>
                <div>
                  <ChatTimeDisplay>{chat.displayTime}</ChatTimeDisplay>
                </div>
              </ChatInfo>
            </ListItem>
          );
          })
        }
      </ContentContainer>
    )
  }
}

export default ChatContainer;
