import React from 'react';
import ContentContainer from "../shared/ContentContainer";
import DisplayLabel from "../shared/DisplayLabel";
import {ArrowBack} from "styled-icons/boxicons-regular";
import { SwatchesPicker } from "react-color";
import {Frown, Smile} from "styled-icons/fa-regular";

import Button from "../shared/Button";
import config from "../../config";
import data from "../../utilities/data";
import Flex from "../shared/Flex";
import getTextColourFromBackgroundColour from "../../utilities/getTextColourFromBackgroundColour";
import HorizontalRule from "../shared/HorizontalRule";
import Input from "../shared/Input";
import MessageContainer from "../shared/MessageContainer";
import SmileyContainer from "../shared/SmileyContainer";

class MyAccount extends React.Component {
  state = {
    colourUpdateText: "",
    passwordUpdateText: "",
    photo: "",
    colour: this.props.colour === null ? config.colours.lapisLazuli : this.props.colour,
    messageRating: 0,
    isEditingColour: false,
    isEditingPassword: false,
    accountData: {},
    newPassword: "",
    confirmNewPassword: ""
  };
  componentDidMount = async () => {
    const accountData = await data.account(this.props.token);

    //console.log("received account data", accountData);

    await this.setState({accountData: accountData});
  };
  editColour = async () => {
    await this.setState({isEditingColour: true});
  };
  editPassword = async () => {
    await this.setState({isEditingPassword: true});
  };
  handleChangeComplete = async e => {
    await this.setState({
      colour: e.hex
    });
    this.updateColour();
  };
  handleChangeNewPassword = async e => {
    this.setState({newPassword: e.currentTarget.value})
  };
  handleChangeConfirmNewPassword = async e => {
    this.setState({confirmNewPassword: e.currentTarget.value})
  };
  handleSmileyClick = async smiley => {
    let newRating = 0;
    if (smiley === 'happy' && this.state.messageRating !== 1)
      newRating = 1;
    if (smiley === 'sad' && this.state.messageRating !== -1)
      newRating = -1;
    await this.setState({
      messageRating: newRating
    });
  };
  updateColour = async () => {
    await this.setState({colourUpdateText:"Saving new colour"});
    const updateColourResult = await data.updateColour(this.props.token, this.state.colour);
    await this.setState({colourUpdateText:"New colour saved!"});
    setTimeout(async () => { this.setState({colourUpdateText:""})}, 2000);
    this.props.setColour(this.state.colour);
  };
  updatePassword = async() => {
    if (this.state.newPassword !== this.state.confirmNewPassword) {
      await this.setState({passwordUpdateText: "Passwords do not match!"});
      return null;
    }
    await this.setState({passwordUpdateText: "Saving new password"});
    const updatePasswordResponse = await data.updatePassword(this.props.token, this.state.newPassword);
    await this.setState({
      passwordUpdateText: "",
      newPassword: "",
      confirmNewPassword: ""
    });
    this.stopEditingPassword();
  };
  stopEditingColour = async () => {
    await this.setState({isEditingColour: false});
  };
  stopEditingPassword = async () => {
    await this.setState({isEditingPassword: false});
  };
  render() {
    return (
      <ContentContainer>
        <DisplayLabel>
          <ArrowBack
            size={32}
            onClick={() => this.props.setChatId(null)}
            style={{marginRight:"10px", cursor: "pointer"}}
          />
          My account
        </DisplayLabel>
        <HorizontalRule />
        <h3>
          {this.state.accountData.firstName} {this.state.accountData.lastName} {this.state.accountData.isParent && ` (Parent account)`}
        </h3>

        <div>
          {
            this.state.isEditingPassword
            &&
            <div style={{margin: "20px"}}>
              <DisplayLabel>Enter new password</DisplayLabel>
              <Input
                type={`password`}
                id="newPassword"
                value={this.state.newPassword}
                onChange={this.handleChangeNewPassword}
              />
              <DisplayLabel>Confirm new password</DisplayLabel>
              <Input
                type={`password`}
                id="confirmNewPassword"
                value={this.state.confirmNewPassword}
                onChange={this.handleChangeConfirmNewPassword}
              />
            </div>
          }
        </div>
        <span style={{color:"red"}}>{this.state.passwordUpdateText}</span>
        <div>
          {
            this.state.isEditingPassword
              ?
              <Flex>
                <Button style={{margin:"5px", maxWidth:"200px",color:"#000000"}} onClick={this.updatePassword}>
                  Save new password
                </Button>
                <Button style={{margin:"5px", maxWidth:"200px",color:"#000000"}} onClick={this.stopEditingPassword}>
                  Cancel
                </Button>
              </Flex>
                :
              <Button style={{margin:"5px", maxWidth:"200px",color:"#000000"}} onClick={this.editPassword}>
                Change my password
              </Button>
          }
        </div>
        <HorizontalRule />
        <h3>Your message box colour</h3>
        <div style={{display: "flex", justifyContent:`flex-start`}}>
          <MessageContainer backgroundColour={this.state.colour} colour={getTextColourFromBackgroundColour(this.state.colour)}>
            <div style={{fontWeight:"bold",marginBottom:"4px"}}>{this.props.firstName}</div>
            All of your messages will appear like this, even ones you have already sent.<br />
            <div style={{textAlign:"right",fontSize:"0.7em",marginTop:"7px"}}>
              Time sent
            </div>
          </MessageContainer>
          <SmileyContainer>
            <Smile size={34} onClick={() => this.handleSmileyClick('happy')} style={{cursor: "pointer", color: this.state.messageRating === 1 ? "#ffff00" : "#ababab"}} />
            <Frown size={34}  onClick={() => this.handleSmileyClick('sad')} style={{cursor: "pointer", color: this.state.messageRating === -1 ? "#454545" : "#ababab"}} />
          </SmileyContainer>
        </div>
        <div>
          {
            this.state.isEditingColour
            ?
            <Button style={{margin:"5px", maxWidth:"200px",color:"#000000"}} onClick={this.stopEditingColour}>
              Hide colour picker
            </Button>
            :
            <Button style={{margin:"5px", maxWidth:"200px",color:"#000000"}} onClick={this.editColour}>
              Change colour
            </Button>
          }
          <span>{this.state.colourUpdateText}</span>
        </div>
        {
          this.state.isEditingColour
          &&
          <div style={{display: "flex", alignItems:`flex-start`, flexDirection:`column`, marginTop:"10px"}}>
            <SwatchesPicker
              height="570px"
              style={{overflow:"hidden"}}
              color={this.state.colour}
              onChangeComplete={ this.handleChangeComplete }
            />
          </div>
        }
      </ContentContainer>
    );
  }
}

export default MyAccount;
