import styled from 'styled-components';

const AppHeader = styled.div`
  position: fixed;
  opacity: 0.8;
  width: 100%;
  background-color: #343434;
  padding: 5px;
  height: 42px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  z-index: 100;
`;

export default AppHeader;
