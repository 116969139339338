import styled from "styled-components";
import config from "../../config";

const ChatTitle = styled.div`
  margin-left: -10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  marginBottom: 10px;
  position: fixed;
  width: 100%;
  top: ${props => props.top ? props.top : `52px`};
  padding: 10px;
  background-color: ${config.colours.taupeGray};
  opacity: 0.9;
`;

export default ChatTitle;
