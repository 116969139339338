import styled from 'styled-components';
import config from '../../config';

const Flex = styled.div`
  display: flex;
  flex-direction: ${props => props.column ? `column` : `row`};
  justify-content: ${props => props.center ? `space-between` : `center`};;
  align-items: center;
  color: ${config.colours.text};
`;

export default Flex;
