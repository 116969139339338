import styled from "styled-components";
import config from "../../config";

const FeelingSelectorContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 90px;
  margin-bottom: 10px;
  border-radius: 7px;
  cursor: pointer;
  padding: 5px;
  
  background-color: ${props => props.isSelected ? `#ababab` : config.colours.background};
  border: ${`2px solid ${config.colours.background}`};
  
  &: hover {
    border: 2px solid #ababab;
  }
`;

export default FeelingSelectorContainer;
